<template>
  <main class="page-container container">
    <div class="section__top" :class="isMobileSmall ? 'mb-10' : 'mb-20'">
      <div class="section__top-left">
        <AppText
                :size="isMobileSmall ? 18 : isMobile ? 22 : 26"
                weight="600"
                :line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
        >
          {{ $t('specializedOrganizations') }}
        </AppText>
      </div>

      <div class="section__top-right d-flex">

        <div class="section__top-search mr-15">
          <app-input
                  class="auction-header__input filter__search"
                  placeholder="Поиск по ИНН"
                  style="width: 220px;"
          >
            <template #prepend>
              <img src="@/assets/icons/search.svg" style="width: 16px" alt="">
            </template>
          </app-input>
        </div>

        <AppButton theme="info" sides="20"
                   height="40"
                   class="mr-15"
        >
          <img src="../../assets/icons/repeat.svg" alt="">
        </AppButton>

        <AppButton theme="info" sides="20"
                   height="40"
                   style="background-color: #E3F8ED"
        >
          <img src="../../assets/icons/excel.svg" alt="">
        </AppButton>

      </div>

    </div>

    <div class="section__body">

      <div class="table-block"
           :class="isMobileMedium ? 'mb-30' : 'mb-60'"
      >
        <table>
          <thead>
          <tr>
            <th class="text-bold">№</th>
            <th class="text-bold">{{ $t('organizationName') }}</th>
            <th class="text-bold">{{ $t('address') }}</th>
            <th class="text-bold">{{ $t('inn') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr class="text-center">
            <td>1</td>
            <td>"Янгийўл ё?-мой" очи? акциядорлик жамияти</td>
            <td>г.Ташкент, Юнусабадский р-н, ул. Матбуотчилар 32,
              100029
            </td>
            <td>200466854</td>
          </tr>
          </tbody>
        </table>
      </div>

      <AppPagination show-list/>

    </div>

  </main>
</template>

<script>
  import AppText from "../../components/shared-components/AppText";
  import AppInput from "../../components/shared-components/AppInput";
  import AppButton from "../../components/shared-components/AppButton";
  import AppPagination from "../../components/shared-components/AppPagination";

  export default {
    name: "index",
    components: {AppPagination, AppButton, AppInput, AppText}
  }
</script>

<style scoped>

</style>
